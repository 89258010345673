import { apiFetcher } from "./apiFetcher";

export const mobilizeiPublicationsService = {
  startPublication,
  finishPublication
};

function startPublication(data) {
  const url = `publications/create_publication_doc`;

  return apiFetcher(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

function finishPublication(data) {
  const url = `publications/finish_publication`;

  return apiFetcher(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}