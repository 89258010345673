import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, InputLabel } from '@material-ui/core';
import _ from 'lodash';
import { Cancel, CheckCircle, InfoRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    sectionText: {
        fontSize: 16,
        color: '#3C4252',
    },
    helpText: {
        fontSize: 16,
        color: '#3C4252',
        fontWeight: 'bold',
        margin: '10px 0 0 0'
    },
    iconCheck: {
        fontSize: 24,
        color: '#3cd568'
    },
    iconUncheck: {
        fontSize: 24,
        color: '#f44336'
    },
    iconInfo: {
        fontSize: 24,
        color: '#3C4252'
    },
}))

export default function Step2({ formData, store, isFinished }) {
    const classes = useStyles();

    const androidData = _.get(store, 'platforms.android', {});
    const iOSData = _.get(store, 'platforms.ios', {});

    const platformData = formData.selectedPlatform  === 'android' ? androidData : iOSData;

    const INITIAL_DATA = []

    if(formData.selectedPlatform === 'android') {
        INITIAL_DATA.push({
            title: 'Google',
            fields: [
                { 
                    title: "Bundle ID",
                    fieldValue: platformData.bundleId 
                        ? platformData.bundleId : formData.firstVersion 
                        ? "Será preenchido automaticamente" : "Não preenchido"
                },
                { 
                    title: "Nome da conta de serviço",
                    fieldValue: platformData.googlePlayServiceAccountName ? platformData.googlePlayServiceAccountName : "Não preenchido"
                },
            ],
            completed: (platformData.bundleId || formData.firstVersion) && platformData.googlePlayServiceAccountName,
            required: true
        })
    } else {
        INITIAL_DATA.push({
            title: 'Apple',
            fields: [
                { 
                    title: "Bundle ID",
                    fieldValue: platformData.packageName 
                        ? platformData.packageName : formData.firstVersion 
                        ? "Será preenchido automaticamente" : "Não preenchido"
                },
                { 
                    title: "Nome da empresa",
                    fieldValue: platformData.teamName ? platformData.teamName : "Não preenchido"
                },
                { 
                    title: "Team ID",
                    fieldValue: platformData.teamID ? platformData.teamID : "Não preenchido"
                },
                { 
                    title: "ID do aplicativo na App Store Connect",
                    fieldValue: platformData.ascAppId ? platformData.ascAppId : "Não preenchido"
                },
            ],
            completed: (platformData.packageName || formData.firstVersion) && platformData.teamName
                && platformData.teamID && (platformData.ascAppId || formData.firstVersion),
            required: true
        })
    }

    const DATA = INITIAL_DATA.concat([
        {
            title: 'Expo',
            fields: [
                { title: "ID do projeto do Expo", fieldValue: store.expoProjectId ? store.expoProjectId : "Não preenchido" },
                { title: "Slug", fieldValue: store.slug ? store.slug : "Não preenchido" },
            ],
            completed: store.expoProjectId && store.slug,
            required: true
        },
        {
            title: 'Firebase',
            fields: [
                { 
                    title: "ID do Projeto",
                    fieldValue: platformData.firebaseApplicationId  
                        ? platformData.firebaseApplicationId : formData.firstVersion
                        ? "Será preenchido automaticamente" : "Não preenchido"
                },
                { 
                    title: "Nome do projeto",
                    fieldValue: store.firebaseProjectName ? store.firebaseProjectName : "Não preenchido"
                },
            ],
            completed: (platformData.firebaseApplicationId || formData.firstVersion) && store.firebaseProjectName,
            required: true
        },
        {
            title: 'Facebook (Opcional)',
            fields: [
                { title: "Facebook ID", fieldValue: formData.facebookId ? formData.facebookId : "Não preenchido" },
                { title: "Facebook Token", fieldValue: formData.facebookToken ? formData.facebookToken : "Não preenchido" },
            ],
            completed: formData.facebookId && formData.facebookToken,
            required: false
        },
    ]);

    useEffect(() => {
        const allCompleted = !DATA.find(x => x.required && !x.completed);

        if(allCompleted){
            return isFinished(true);
        }

        return isFinished(false)
    }, [])

    return (
        <Grid container direction='column'>
            <Grid item xs={12} spacing={2}>
                {DATA.map((item, index) => (
                    <Grid key={index} item>
                        <Box display='flex' flexDirection='row' alignItems='center' gridColumnGap={20}>
                            {item.completed
                                ? <CheckCircle className={classes.iconCheck} /> : item.required 
                                ? <Cancel className={classes.iconUncheck} /> : <InfoRounded className={classes.iconInfo} /> 
                            }
                            <h2 className={classes.sectionText}>
                                {item.title}
                            </h2>
                        </Box>

                        <Grid container direction='row' xs={12}>
                            {item.fields.map((itemData, itemIndex) => (
                                <Box key={itemIndex} mb={2} mt={2}>
                                    <InputLabel htmlFor="expoProjectId" shrink>
                                        {itemData.title}
                                    </InputLabel>

                                    <InputLabel className={classes.helpText} shrink>
                                        {itemData.fieldValue}
                                    </InputLabel>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}