import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import _ from 'lodash';
import { Chip, Tooltip } from '@material-ui/core';
import AndroidIcon from '@material-ui/icons/AndroidRounded';
import AppleIcon from '@material-ui/icons/Apple';

export const formatFirestoreTimestamp = (timestamp, format) => {
    if (_.isEmpty(timestamp) || _.isEmpty(format)) {
        return null;
    }

    let seconds = timestamp._seconds
    if (_.isNil(seconds)) seconds = timestamp.seconds;

    let nanoseconds = timestamp._nanoseconds;
    if (_.isNil(nanoseconds)) nanoseconds = timestamp.nanoseconds;

    const firstoreTimestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);
    const jsDate = firstoreTimestamp.toDate();
    return moment(jsDate).format(format);
}

export const getPublishStatusChip = (publishStatus) => {
    if (_.isEmpty(publishStatus))
        return '-';

    let color = "";
    let label = "";
    let tooltip = "";

    switch (publishStatus) {
        case 'first_publish_pending':
            color = "#78909c";
            label = "Pendente";
            tooltip = "O cliente ainda não solicitou a publicação desta versão do aplicativo";
            break;
        case 'pending_listing_creation':
            color = "#ffc400";
            label = "Aguardando criação na loja";
            tooltip = "O cliente solicitou a publicação do seu aplicativo pela primeira vez. É preciso criar a listagem do aplicativo na loja do Google ou da Apple.";
            break;
        case 'developer_account_pending':
            color = "#ff5500";
            label = "Aguardando conta de desenvolvedor";
            tooltip = "O cliente solicitou a publicação do seu aplicativo mas ainda não criou sua conta de desenvolvedor Apple ou Google Play.";
            break;
        case 'publishing':
            color = "#ff9100";
            label = "Aguardando publicação";
            tooltip = "É preciso gerar e enviar uma atualização do aplicativo para a loja do Google ou da Apple";
            break;
        case 'pending_google_play_developer_account_verification':
            color = "#bb3df5";
            label = "Aguardando verificação da conta de desenvolvedor Google Play";
            tooltip = "O cliente deve realizar a verificação de identidade da sua conta de desenvolvedor na Google Play para que o aplicativo possa ser publicado.";
            break;
        case 'pending_apple_account_renewal':
            color = "#7f7aff";
            label = "Aguardando renovação da conta Apple";
            tooltip = "A assinatura da conta de desenvolvedor Apple do cliente está expirada e deve ser renovada para que o aplicativo possa ser publicado.";
            break;
        case 'pending_apple_account_terms_agreement':
            color = "#bb3df5";
            label = "Aguardando aceite de novos termos";
            tooltip = "O cliente deve aceitar os novos termos de uso disponíveis na sua conta de desenvolvedor Apple para que uma atualização do seu aplicativo possa ser publicada.";
            break;
        case 'in_review':
            color = "#b26500";
            label = "Em análise";
            tooltip = "A atualização do aplicativo foi enviado para a loja do Google ou da Apple e está em fase de aprovação";
            break;
        case 'published':
            color = "#43a047";
            label = "Publicado";
            tooltip = "O aplicativo está disponível para download e o cliente foi informado sobre isso";
            break;
        case 'pending_update':
            color = "#78909c";
            label = "Atualização pendente";
            tooltip = "O cliente fez modificações que exigem a publicação de uma atualização do seu aplicativo mas ainda não solicitou a sua publicação";
            break;
        default:
            break;
    }

    return (
        <Tooltip title={tooltip} arrow>
            <Chip label={label} size="small" style={{ backgroundColor: color, color: 'white', width: 180 }} />
        </Tooltip>
    )
}

export const getStoreStatusChip = (storeStatus) => {
    if (_.isEmpty(storeStatus))
        return '-';

    let color = "";
    let label = "";
    let tooltip = "";

    switch (storeStatus) {
        case 'on_trial':
            color = "#78909c";
            label = "Período de testes";
            tooltip = "O cliente está no seu período de testes.";
            break;
        case 'trial_ended':
            color = "#ffc400";
            label = "Teste finalizado";
            tooltip = "O período de testes do cliente foi finalizado e ele ainda não realizou a contratação do serviço.";
            break;
        case 'active':
            color = "#43a047";
            label = "Ativo";
            tooltip = "A conta do cliente stá ativa.";
            break;
        case 'suspended':
            color = "#ff3d00";
            label = "Suspenso";
            tooltip = "A conta do cliente está suspensa por falta de pagamento ou por algum outro motivo.";
            break;
        case 'archived':
            color = "#383838";
            label = "Arquivado";
            tooltip = "O cliente está arquivado.";
            break;
        default:
            break;
    }

    return (
        <Tooltip title={tooltip} arrow>
            <Chip label={label} size="small" style={{ backgroundColor: color, color: 'white', width: 130 }} />
        </Tooltip>
    )
}

export const getPublicationStatusChip = (publicationStatus) => {
    if (_.isEmpty(publicationStatus))
        return '-';

    let color = "";
    let label = "";
    let tooltip = "";

    switch (publicationStatus) {
        case 'queued':
            color = "#34495E";
            label = "Na fila";
            tooltip = "Aguardando o início do processo.";
            break;
        case 'build':
            color = "#54A0FF";
            label = "Em construção";
            tooltip = "O aplicativo está sendo construído.";
            break;
        case 'submit':
            color = "#9B59B6";
            label = "Enviando";
            tooltip = "O aplicativo está sendo enviado.";
            break;
        case 'metadata':
            color = "#ff9100";
            label = "Enviando metadados";
            tooltip = "Atualizando informações na loja de aplicativos.";
            break;       
        case 'ready':
            color = "#F1C40F";
            label = "Pronto";
            tooltip = "O Aplicativo está pronto para ser enviado para revisão manualmente.";
            break;
        case 'finished':
            color = "#30CB83";
            label = "Publicado";
            tooltip = "Publicação finalizada.";
            break;
        case 'errored':
            color = "#ff3d00";
            label = "Erro";
            tooltip = "Erro na publicação.";
            break;
        default:
            break;
    }

    return (
        <Tooltip title={tooltip} arrow>
            <Chip label={label} size="small" style={{ backgroundColor: color, color: 'white', width: 130 }} />
        </Tooltip>
    )
}

export const getMobilePlatformChip = (mobilePlatform) => {
    if (_.isEmpty(mobilePlatform))
        return '-';

    let color = "";
    let label = "";
    let tooltip = "";
    let icon = "";

    switch (mobilePlatform) {
        case 'android':
            color = "#43a047";
            label = "Android";
            tooltip = "Android.";
            icon = <AndroidIcon style={{ color: 'white' }} />;
            break;
        case 'ios':
            color = "#000000";
            label = "iOS";
            tooltip = "iOS.";
            icon = <AppleIcon style={{ color: 'white' }} />;
            break;
        default:
            break;
    }

    return (
        <Tooltip title={tooltip} arrow>
            <Chip 
                icon={icon}
                label={label}
                size="small"
                style={{ backgroundColor: color, color: 'white'}} 
            />
        </Tooltip>
    )
}

export const mapFirestoreTimestampToMoment = (timestamp) => {
    if (_.isEmpty(timestamp)) {
        return null;
    }

    let seconds = timestamp._seconds
    if (_.isNil(seconds)) seconds = timestamp.seconds;

    let nanoseconds = timestamp._nanoseconds;
    if (_.isNil(nanoseconds)) nanoseconds = timestamp.nanoseconds;

    const firstoreTimestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);
    const jsDate = firstoreTimestamp.toDate();
    return moment(jsDate);
}