import { apiFetcher } from "./apiFetcher";
import axios from "axios";

export const mobilizeiStoresService = {
  createStore,
  getStoreById,
  getStoreFeatures,
  getThemeById,
  getStorePanelUrl,
  getStoreBillingPanelUrl,
  setPublishStatus,
  deleteFirebaseApps
};

function createStore(platformId, data) {
  const url = `platforms/${platformId}/stores`

  return apiFetcher(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

function getStoreById(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getStoreFeatures(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}/features`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getThemeById(platformId, storeId, themeId) {
  const url = `platforms/${platformId}/stores/${storeId}/themes/${themeId}`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getStorePanelUrl(userId) {
  const url = `users/${userId}/panel_url`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function getStoreBillingPanelUrl(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}/billing_panel_url`

  return apiFetcher(url, {
    method: "GET"
  }).then(response => response);
}

function setPublishStatus(platformId, storeId, data) {
  const url = `platforms/${platformId}/stores/${storeId}/publish_status`

  return apiFetcher(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(response => response);
}

function deleteFirebaseApps(platformId, storeId) {
  const url = `platforms/${platformId}/stores/${storeId}/delete_firebase_apps`

  return apiFetcher(url, {
    method: "DELETE",
  }).then(response => response);
}