import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import _ from 'lodash';
import { mobilizeiPublicationsService } from '../_services/mobilizei/publications.service';
import { mobilePlatforms } from '../_constants/mobilePlatforms';
import AndroidProductionImage from '../_assets/img/publications/android-production.png';
import AndroidCreateNewVersionImage from '../_assets/img/publications/android-create-new-version.png';
import iOSCompilationImage from '../_assets/img/publications/ios-compilation.png';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 9999,
    },
    content: {
        maxHeight: '88%',
        maxWidth: '70%',
        width: 'fit-content',
        margin: 'auto',
        scrollY: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    sectionText: {
        fontSize: 14,
        color: '#3C4252',
    },
    helpText: {
        fontSize: 12,
        color: '#3C4252',
    },
    helpTextBold: {
        fontSize: 12,
        color: '#000',
        fontWeight: 'bold'
    },
    image: {
        border: 'solid 1px #ececec',
        height: 400,
    },
    buttonIcon: {
        fontSize: 16
    },
    propertyContainer: {
        '& a': {
          color: 'black',
          textDecoration: 'none'
        }
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 0
    },
    value: {
        display: '-webkit-box',
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical'
    },
}))

export default function FinishPublication({ data, isOpen, onClose, onDataUpdate }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    function handleBundleDownload(){
        if(data.buildUrl){
            return window.open(data.buildUrl, '_blank')
        }
    
        alert('Não foi possível obter o link de download do bundle.')
    }

    function handleFinishPublication(){
        setLoading(true);
        
        const dataToSend = { publicationId: data.id }

        mobilizeiPublicationsService.finishPublication(dataToSend)
            .then(() => {
                onClose();
                onDataUpdate();
            })
            .catch((e) => {
                console.log(e)
                alert(`Não foi possível salvar as informações da loja`)
            })
            .finally(() => setLoading(false))
    }

    const handleChange = (event) => {
        setChecked(event.target.checked)
    }

    const copyToClipboard = (value) =>
        navigator.clipboard.writeText(value)

    function TextProperty({ data, label, name }) {
        return (
            <div className={classes.propertyContainer}>
                <p className={classes.label}> {`${label}`}
                    <IconButton className={classes.iconButton} onClick={() => copyToClipboard(data[name])}>
                        <FileCopyIcon className={classes.buttonIcon} />
                    </IconButton>
                </p>
                <p className={classes.value}>{data[name] || '-'}</p>
            </div>
        )
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <h2 align="center" className={classes.title}>Concluir a publicação</h2>

            {data.mobilePlatform === mobilePlatforms.ANDROID && (
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <TextProperty
                            data={data.metadata}
                            label="Conta de desenvolvedor"
                            name='developerAccountName'
                        />
                    </Grid>

                    <Grid item>
                        <h2 className={classes.sectionText}>
                            Passo 1: Faça o download do bundle
                        </h2>

                        <Button variant="outlined" color="primary" onClick={handleBundleDownload}>
                            Fazer download do bundle
                        </Button>
                    </Grid>

                    <Grid item>
                        <h2 className={classes.sectionText}>
                            Passo 2: Criar uma nova versão de Produção no Google Play Console
                        </h2>

                        <Grid container spacing={2} style={{ padding: 20 }}>
                            <Grid item>
                                <img className={classes.image} src={AndroidProductionImage} />
                            </Grid>
                            <Grid item>
                                <img className={classes.image} src={AndroidCreateNewVersionImage} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <h2 className={classes.sectionText}>
                            Passo 3: Fazer o upload do arquivo e enviar para a análise
                        </h2>
                    </Grid>
                </Grid>
            )}

            {data.mobilePlatform === mobilePlatforms.IOS && (
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <TextProperty
                            data={data.metadata}
                            label="Conta de desenvolvedor"
                            name='teamName'
                        />
                    </Grid>

                    <Grid item>
                        <h2 className={classes.sectionText}>
                            Passo 1: Acessar a App Store Connect com a conta de desenvolvedor do cliente
                        </h2>
                    </Grid>

                    <Grid item>
                        <h2 className={classes.sectionText}>
                            Passo 2: Selecionar a compilação (será correspondente à versão)
                        </h2>

                        <Grid item>
                            <img className={classes.image} src={iOSCompilationImage} />
                        </Grid>
                    </Grid>

                    <Grid item>
                        <h2 className={classes.sectionText}>
                            Passo 3: Enviar o aplicativo para revisão
                        </h2>
                    </Grid>
                </Grid>
            )}

            <Box display="flex" justifyContent="center" pt={2}>
                <FormControlLabel 
                    control={<Checkbox onChange={handleChange} />}
                    label={"Confirmo que realizei corretamente os passos acima na loja de aplicativos."}
                />
            </Box>

            <Grid container justify='center' direction='column' style={{ paddingTop: 20, paddingBottom: 20 }} >
                <h2 align="center" className={classes.helpText}>
                    Após concluir todos os passo clique no botão abaixo para encerrar a publicação
                </h2>

                <h2 align="center" className={classes.helpTextBold}>
                    O STATUS DE PUBLICAÇÃO DA LOJA será alterado automaticamente
                </h2>

                <Grid container justify="center" item xs={12}>
                    {loading ? <CircularProgress /> :
                        <Button disabled={!checked} variant="contained" color="primary" onClick={handleFinishPublication}>
                            Finalizar a publicação
                        </Button>
                    }
                </Grid>
            </Grid>   
        </ReactModal>
    )
}