import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import HelpIcon from '@material-ui/icons/Help';
import { mobilizeiPublicationsService } from '../_services/mobilizei/publications.service';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 999
    },
    content: {
        height: '80%',
        width: 'auto',
        maxWidth: '60%',
        margin: 'auto',
        scrollY: 'auto',
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    helpButton: {
        position: 'absolute',
        top: 12,
        right: 0
    },
    saveButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 60,
        gap: 20,
    },
}))

export default function StartPublicationModal({ data, isOpen, onClose, onDataUpdate, platformId }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState(0);

    const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false); 

    const [formData, setFormData] = useState({
        firstVersion: '',
        selectedPlatform: '',
        exclusiveApp: ''
    })

    const handleFormData = (property, value) => {
        setFormData({
            ...formData,
            [property]: value
        })
    }

    const handlePreviousStep = () => {
        if(step > 0){
            setStep(step-1);
        }
        setIsNextButtonEnabled(false);
    }

    const handleNextStep = () => {
        setStep(step+1);
        setIsNextButtonEnabled(false);
    }

    const handleFinishStep = (isFinished) => {
        setIsNextButtonEnabled(isFinished)
    }

    const handleStartPublication = () => {
        setLoading(true);

        let dataToSend = {
            storeId: data.id,
            platformId: data.platformId,
            mobilePlatform: formData.selectedPlatform,
            firstVersion: formData.firstVersion,
        }

        if(formData.selectedPlatform === 'android'){
            dataToSend = {
                ...dataToSend,
                exclusiveApp: false,
                runBuild: true,
                runSubmit: formData.firstVersion ? false : true,
            }
        } else if (formData.selectedPlatform === 'ios'){
            dataToSend = {
                ...dataToSend,
                exclusiveApp: formData.exclusiveApp,
                runBuild: (formData.exclusiveApp || formData.firstVersion) ? false : true,
                runSubmit: (formData.exclusiveApp || formData.firstVersion) ? false : true,
            }
        }

        mobilizeiPublicationsService.startPublication(dataToSend)
                .then(() => {
                    onDataUpdate();
                    onClose();
                })
                .catch((e) => {
                    const code = e?.error?.code;
                    
                    if(code === '9100' && e?.error?.messages){
                        return alert(`Erro ao inicar a publicação: ${e?.error?.messages.map(x => x)}`)
                    }

                    alert(`Erro ao inicar a publicação: ${e?.error?.message}`)
                })
                .finally(() => setLoading(false))
    }

    const STEPS = [
        {
            Component: <Step1  formData={formData} handleChangeData={handleFormData} isFinished={handleFinishStep} />
        },
        {
            Component: <Step2 formData={formData} store={data} isFinished={handleFinishStep} />
        },
        {
            Component: <Step3 formData={formData} store={data} isFinished={handleFinishStep} />
        }
    ]
    
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <Box display='flex' flexDirection='column' justifyContent='space-between' style={{ height: '100%' }}>
                <Grid container>
                    <h2 align="center" className={classes.title}>
                        Iniciar o processo de publicação
                    </h2>

                    <Tooltip className={classes.helpButton} title="Em dúvida sobre o processo de publicação de um aplicativo? Clique aqui para visualizar suas etapas.">
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => {
                                window.open('https://docs.google.com/document/d/1UyPmc-PEoYzqwBjGqdSmG3DScaSohRvsYmDcYxUwiAo/edit?tab=t.0', '_blank')
                            }}
                        >
                            <HelpIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>

                    {STEPS[step] && (STEPS[step].Component)}
                </Grid>

                {loading 
                    ? <Box className={classes.saveButton} pb={4}>
                        <CircularProgress />
                    </Box>
                    : <Box className={classes.saveButton} pb={4}>
                        {step > 0 &&
                            <Button variant="outlined" color="primary" onClick={handlePreviousStep}>
                                Voltar
                            </Button>
                        }

                        {step === STEPS.length - 1
                            ? <Button 
                                disabled={!isNextButtonEnabled}
                                variant="contained"
                                color="primary"
                                onClick={handleStartPublication}
                            >
                                Iniciar publicação
                            </Button>
                            : <Button disabled={!isNextButtonEnabled} variant="contained" color="primary" onClick={handleNextStep}>
                                Próximo
                            </Button>
                        }
                    </Box>
                }
            </Box>
        </ReactModal>
    )
}