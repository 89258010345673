import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import _ from 'lodash';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';
import { withFirebase } from '../_firebase';
import 'firebase/firestore';
import AndroidIcon from '@material-ui/icons/AndroidRounded';
import AppleIcon from '@material-ui/icons/Apple';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 999
    },
    content: {
        height: 'fit-content',
        width: '35%',
        margin: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    subtitle: {
        fontSize: 16,
        color: '#3C4252',
    },
    text: {
        fontSize: 14,
        color: '#a1a1a1',
    },
    alert: {
        fontSize: 14,
        color: '#ff2457',
    }
}))

function DeleteFirebaseAppsDialog({ data, isOpen, onClose, onDataUpdate, platformId }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const androidAppId = _.get(data, 'platforms.android.firebaseApplicationId', '');
    const androidAppBundle = _.get(data, 'platforms.android.bundleId', '');
    const canDeleteAndroidApp = androidAppBundle ? androidAppId : true;

    const iosAppId = _.get(data, 'platforms.ios.firebaseApplicationId', '');
    const iosAppBundle = _.get(data, 'platforms.ios.packageName', '');
    const canDeleteiOSApp = iosAppBundle ? iosAppId : true;

    const handleDelete = () => {
        setLoading(true);

        mobilizeiStoresService.deleteFirebaseApps(platformId, data.id)
          .then(() => {
              onDataUpdate();
              onClose();
          })
          .catch((e) => {
              console.log(e)
              alert(`Não foi possível excluir os aplicativos do firebase.`)
          })
          .finally(() => setLoading(false))
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <h2 align="center" className={classes.title}>Excluir aplicativos do firebase</h2>

            <Box alignItems="center" justify='center' m={6} >
                <h4 align="center" className={classes.subtitle}>
                  Deseja realmente excluir os aplicativos do Firebase?
                </h4>

                <Box display='grid' justifyContent='center' gridGap={10}>
                    {androidAppId ? (
                        <Box display='flex' flexDirection='row' alignItems='center' gridGap={20}>
                            <AndroidIcon/>
                            <h4 className={classes.text}>
                                {androidAppId}
                            </h4>
                        </Box>
                    ) : <>
                        {androidAppBundle && (
                            <Box display='flex' flexDirection='row' alignItems='center' gridGap={20}>
                                <AndroidIcon/>
                                <h4 className={classes.text}>
                                    Esse aplicativo possui um bundleId, preencha o ID do aplicativo do Firebase para excluir o aplicativo
                                </h4>
                            </Box>
                        )}
                    </>}
                    
                    {iosAppId ? (
                        <Box display='flex' flexDirection='row' alignItems='center' gridGap={20}>
                            <AppleIcon/>
                            <h4 className={classes.text}>
                                {iosAppId}
                            </h4>
                        </Box>
                    ) : <>
                        {iosAppBundle && (
                            <Box display='flex' flexDirection='row' alignItems='center' gridGap={20}>
                                <AppleIcon/>
                                <h4 className={classes.text}>
                                    Esse aplicativo possui um bundleId, preencha o ID do aplicativo do Firebase para excluir o aplicativo
                                </h4>
                            </Box>
                        )}
                    </>}
                </Box>
            </Box>

            <Grid container alignItems="flex-start" spacing={2}>
                <Grid container justify="center" item xs={12}>
                    {canDeleteAndroidApp && canDeleteiOSApp 
                        ? (
                            <>
                                {loading ? <CircularProgress /> :
                                    <Button variant="contained" color="primary" onClick={handleDelete}>
                                        Excluir aplicativos
                                    </Button>
                                }
                            </>
                        ) 
                        : (
                            <>
                                <Button disabled variant="contained" color="primary">
                                    Nenhum aplicativo para excluir
                                </Button>
                                <h4 align="center" className={classes.alert} color='secondary'>
                                    Verifique se o ID do aplicativo do firebase está preenchido
                                </h4>
                            </>
                        )
                    }
                </Grid>
            </Grid>
        </ReactModal>
    )
}

export default withFirebase(DeleteFirebaseAppsDialog);